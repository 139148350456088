import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BaseContentsModel, UserModel } from '@src/types/models';
import { Box, BoxProps, CircularProgress, MenuItem, Select, styled } from '@mui/material';
import { useUserContentsQuery } from '@api/usercontents';
import { useInView } from 'react-intersection-observer';
import { ContentTypeTitles, UserContentTypes } from '@src/types';
import SearchInput from '@components/SearchInput';
import InputGroup from '@components/InputGroup';
import SearchButton from '@components/SearchButton';
import SearchForm from '@components/Layout/SearchForm';
import { ContentFilterType, ContentFilterTypes } from '@src/types/usercontent';
import FilterList from '@components/UserContentCard/FilterList';
import AddUserContentFilterButton from '@components/UserContentCard/AddUserContentFilterButton';
import ContentItem from '@components/UserCard/ContentItem';

type ContentInfoTabProps = BoxProps & {
  user: UserModel;
  maxShownFilters?: number;
};

const ContentListTab = styled(({ user, maxShownFilters = 1, ...props }: ContentInfoTabProps) => {
  const [userContents, setUserContents] = useState<BaseContentsModel[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const prevVideoRef = useRef<HTMLVideoElement | null>(null);
  const itemsContainerRef = useRef<HTMLDivElement | null>(null);
  const [searchInputError, setSearchInputError] = useState<string | null>(null);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [searchByType, setSearchByType] = useState<UserContentTypes>(UserContentTypes.ALL);
  const [filter, setFilter] = useState<ContentFilterType>({});

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { isFetching, data } = useUserContentsQuery({
    page,
    page_size: 5,
    // order: orderBy && (order === 'asc' ? orderBy : `-${orderBy}`),
    include: 'counters',
    filter: {
      ...filter,
      user__in: [user.id],
    },
  });

  const existsSearchValue = useMemo(() => !!searchInputValue.trim().length, [searchInputValue]);

  const existsFilters = useMemo(() => !!Object.values(filter).filter(Boolean).length, [filter]);

  const videoPlayHandler = (e: Event) => {
    const video = e.target as HTMLVideoElement;
    if (prevVideoRef.current && prevVideoRef.current.id !== video.id && !prevVideoRef.current.paused) {
      prevVideoRef.current.pause();
    }
    prevVideoRef.current = video;
  };

  const clearSearchField = () => {
    setSearchInputValue('');
    setPage(0);
  };

  const clearFilters = () => {
    setFilter({});
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFilter(filter => {
      const value = searchInputValue.trim();

      if (searchByType !== UserContentTypes.ALL) {
        return { ...filter, type__in: [searchByType] };
      }

      return { ...filter };
    });
    setPage(0);
  };

  useEffect(() => {
    setUserContents([]);
  }, [user.id]);

  useEffect(() => {
    setUserContents([]);
    setPage(1);
  }, [filter]);

  useEffect(() => {
    let videos: HTMLVideoElement[] = [];

    if (itemsContainerRef.current) {
      videos = Array.from(itemsContainerRef.current.querySelectorAll('video'));
    }

    videos.forEach(video => {
      video.addEventListener('play', videoPlayHandler);
    });

    return () => {
      videos.forEach(video => {
        video.removeEventListener('play', videoPlayHandler);
      });
    };
  }, [userContents]);

  useEffect(() => {
    if (!isFetching && data) {
      setUserContents(prevState => {
        const result = [...prevState];
        data.results.forEach(it => {
          const index = result.findIndex(it2 => it2.id === it.id);
          if (index !== -1) {
            result[index] = { ...result[index], ...it };
          } else {
            result.push(it);
          }
        });
        return result;
      });
      setTotalCount(data.pagination.total_count);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (inView && userContents.length < totalCount) {
      setPage(p => p + 1);
    }
  }, [inView]);

  return (
    <Box {...props}>
      <Box sx={{ pt: 2, pb: 1.5, px: 3 }}>
        <SearchForm fullWidth onSubmit={submitHandler}>
          <InputGroup>
            <SearchInput
              onFocus={() => setSearchInputError(null)}
              onChange={e => setSearchInputValue(e.target.value)}
              onClear={clearSearchField}
              value={searchInputValue}
            />
            <Select size="small" value={searchByType} onChange={e => setSearchByType(e.target.value as UserContentTypes)}>
              {Object.entries(ContentTypeTitles).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </InputGroup>
          <SearchButton
            type="submit"
            disabled={!existsSearchValue && !searchInputError}
            sx={{
              ml: 1,
              '.MuiSvgIcon-root': {
                fontSize: 16,
              },
            }}
          />
        </SearchForm>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1.5 }}>
          <AddUserContentFilterButton disableFilters={[ContentFilterTypes.USER]} onFilterChange={setFilter} filter={filter} sx={{ mr: 1 }} />
          {existsFilters && (
            <FilterList
              maxShownFilters={maxShownFilters}
              onClearFilters={clearFilters}
              onFilterChange={setFilter}
              clearButtonUseIcon
              filter={filter}
              sx={{
                display: 'inline-flex',
                ml: 0.5,
              }}
            />
          )}
        </Box>
      </Box>
      <Box ref={itemsContainerRef} sx={{ flexGrow: 1, overflow: 'auto', py: 0, px: 3 }}>
        {userContents.map((userContent, i) => (
          <React.Fragment key={userContent.id}>
            <ContentItem ref={userContents.length - 3 == i ? ref : undefined} userContent={userContent} />
            {i + 1 < userContents.length && <hr />}
          </React.Fragment>
        ))}
        <Box sx={{ my: 2 }}>
          {isFetching && (
            <CircularProgress
              size={30}
              sx={{
                display: 'block',
                mx: 'auto',
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
})(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  height: '100%',
  hr: {
    margin: theme.spacing(2, 0),
  },
}));

export default ContentListTab;
