import React, { useMemo, useState } from 'react';
import { Box, BoxProps, Button, Chip, styled, TextField, Typography } from '@mui/material';
import { ContentLocationStatuses, UserVideoContentModel } from '@src/types/models';
import { useContentItemContext } from '@components/UserCard/types';
import UserContentVideo from '@components/UserContentCard/UserContentVideo';
import CardFormButtonGroup from '@components/CardFormButtonGroup';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { STREAM_ARCHIVE_HOST, STREAM_LIVE_HOST, VIDEO_CDN_HOST } from '@src/config';
import { UserContentTypes } from '@src/types';
import { LiveStreamIcon } from '@components/icons';
import { useChangeContentDataMutation } from '@api/usercontents';

export type VideoContentItemProps = Omit<BoxProps, 'onPlay'> & {
  userContent: UserVideoContentModel;
  onPlay?: (video: HTMLVideoElement) => void;
};

const VideoContentItem = styled(({ userContent, onPlay, ...props }: VideoContentItemProps) => {
  const { editable, setEditable } = useContentItemContext();
  const [text, setText] = useState(userContent.content.title);
  const [formData, setFormData] = useState({ text });
  const [changeContentData] = useChangeContentDataMutation();

  const src = useMemo(() => {
    const uuid = userContent.content.uuid!;

    if (userContent.type === UserContentTypes.VIDEO) {
      return `${VIDEO_CDN_HOST}/${uuid?.replace(/-/g, '')}-master.m3u8`;
    }

    const host = userContent.content.location_status === ContentLocationStatuses.ARCHIVE ? STREAM_ARCHIVE_HOST : STREAM_LIVE_HOST;

    return `${host}/${uuid?.toUpperCase()}/livestream.m3u8`;
  }, [userContent.type, userContent.content.location_status]);

  const live = useMemo(
    () => userContent.type === UserContentTypes.STREAM && userContent.content.location_status === ContentLocationStatuses.LIVE,
    [userContent.type, userContent.content.location_status],
  );
  const cancelChanges = () => {
    setEditable(false);
    setFormData({ text });
  };

  const savePost = async () => {
    try {
      await changeContentData({
        contentId: userContent.content.id,
        content: {
          title: formData.text,
        },
      }).unwrap();
      setEditable(false);
    } catch (error) {}
  };

  const removeVideo = () => {};
  const changeText = (text: string) => {
    setFormData(data => ({ ...data, text }));
  };

  return (
    <Box {...props}>
      <Box className={'VideoContentItem__video'}>
        <UserContentVideo videoId={`UserContentVideo-${userContent.id}`} onPlay={onPlay} live={live} src={src}>
          {userContent.type === UserContentTypes.STREAM && (
            <Box className="VideoContentItem__stream_chip">
              {userContent.content.location_status === ContentLocationStatuses.ARCHIVE ? (
                <Chip label={<>Запись эфира</>} sx={{ background: '#9CA5A5' }} />
              ) : (
                <Chip
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <LiveStreamIcon sx={{ mr: 0.5 }} /> В эфире
                    </Box>
                  }
                  sx={({ palette }) => ({
                    background: palette.primary.main,
                  })}
                />
              )}
            </Box>
          )}
        </UserContentVideo>
        {editable && (
          <Box className="VideoContentItem__video_delete_btn">
            <Button onClick={removeVideo}>
              <DeleteIcon /> Удалить
            </Button>
          </Box>
        )}
      </Box>
      {editable ? (
        <TextField onChange={e => changeText(e.target.value)} value={formData.text} sx={{ mt: 1.5 }} size="small" multiline fullWidth />
      ) : (
        <Typography sx={{ my: 1 }}>{text}</Typography>
      )}
      {editable && <CardFormButtonGroup onCancel={cancelChanges} onSave={savePost} />}
    </Box>
  );
})(({ theme }) => ({
  '.VideoContentItem': {
    '&__stream_chip': {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      '.MuiChip-root': {
        height: 24,
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: 10,
        color: 'white',
        fontFamily: 'Inter',
        borderRadius: theme.spacing(1),
        '.MuiChip-label': {
          padding: theme.spacing(0, 1),
        },
      },
    },
    '&__video': {
      position: 'relative',
      width: '100%',
      borderRadius: '10px',
      overflow: 'hidden',
      '&_delete_btn': {
        inset: 0,
        position: 'absolute',
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFF',
        background: 'rgba(0,0,0,0.6)',
        flexDirection: 'column',
        '.MuiButton-root': {
          color: '#FFF',
          flexDirection: 'column',
          textTransform: 'none',
          '.MuiSvgIcon-root': {
            fontSize: 40,
          },
        },
      },
    },
  },
}));

export default VideoContentItem;
