import moment from 'moment';

export const dateFormat = (value: string) => moment(value).format('DD.MM.YYYY');
export const dateTimeFormat = (value: string) => moment(value).format('DD.MM.YYYY HH:mm');
export const timeFormat = (value: string) => moment(value).format('HH:mm');

export const formatVideoTime = (seconds: number) => {
  if (seconds >= 0) {
    return moment.utc(moment.duration(seconds, 'seconds').asMilliseconds()).format((seconds / 60 >= 60 ? 'HH:' : '') + 'mm:ss');
  } else {
    return '00:00';
  }
};
export const formatDateToISO = (dateString: string) => {
  let date;
  // Check if the input format is "YYYY-MM-DD"
  const isISOFormat = /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  if (isISOFormat) {
    date = new Date(dateString); // Directly assign the ISO format date
  } else {
    // Assume format is "DD.MM.YYYY"
    const [day, month, year] = dateString.split('.');
    // Validate date components
    if (!day || !month || !year) {
      throw new Error(`Invalid date format: ${dateString}`);
    }
    // Create the date object in YYYY-MM-DD format
    date = new Date(`${year}-${month}-${day}T00:00:00.000Z`);
  }
  // Validate the date object
  if (isNaN(date.getTime())) {
    throw new Error(`Invalid Date: ${dateString}`);
  }
  return date.toISOString();
};

export const formatDateToDDMMYYYY = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};
