import React, { useState } from 'react';
import { Box, BoxProps, Button, styled, TextField, Typography } from '@mui/material';
import { UserPostContentModel } from '@src/types/models';
import { useContentItemContext } from '@components/UserCard/types';
import { IMAGE_URL } from '@src/config';
import Grid from '@mui/material/Unstable_Grid2';
import { Delete } from '@mui/icons-material';
import CardFormButtonGroup from '@components/CardFormButtonGroup';
import { useChangeContentDataMutation } from '@api/usercontents';
import { useChangeCommentDataMutation } from '@api/comments';

export type PostContentItemProps = BoxProps & {
  userContent: UserPostContentModel;
};

const PostContentItem = styled(({ userContent, ...props }: PostContentItemProps) => {
  const { editable, setEditable } = useContentItemContext();
  const [images, setImages] = useState(userContent.content?.images || []);
  const [text, setText] = useState(userContent.content.text);
  const [formData, setFormData] = useState({ images, text });
  const oneColumn = (editable ? formData.images : images).length == 1;

  const [changeContentData] = useChangeContentDataMutation();
  const [changeCommentData] = useChangeCommentDataMutation();

  const cancelChanges = () => {
    setEditable(false);
    setFormData({ images, text });
  };

  // const savePost = () => {
  //   setImages(formData.images);
  //   setText(formData.text);
  //   setEditable(false);
  // };

  const savePost = async () => {
    try {
      if (userContent.type === 1) {
        await changeCommentData({
          commentId: userContent.content.id,
          content: {
            text: formData.text,
          },
        }).unwrap();
      } else if (userContent.type === 4) {
        await changeContentData({
          contentId: userContent.content.id,
          content: {
            text: formData.text,
          },
        }).unwrap();
      }
      setEditable(false);
    } catch (error) {}
  };

  const removeImage = (image: string) => {
    setFormData(data => ({
      ...data,
      images: [...data.images.filter(it => it !== image)],
    }));
  };

  const changeText = (text: string) => {
    setFormData(data => ({ ...data, text }));
  };

  return (
    <Box {...props}>
      <Grid container spacing={1}>
        {(editable ? formData.images : images).map(it => (
          <Grid key={it} xs={oneColumn ? 12 : 6}>
            <Box className={'PostContentItem__image ' + (oneColumn ? 'oneColumn' : '')}>
              <img src={IMAGE_URL(200, it)} alt="" />
              {editable && (
                <Box className="PostContentItem__image_delete_btn">
                  <Button onClick={() => removeImage(it)}>
                    <Delete /> Удалить
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
      {editable ? (
        <TextField onChange={e => changeText(e.target.value)} value={formData.text} sx={{ mt: 1.5 }} size="small" multiline fullWidth />
      ) : (
        <Typography sx={{ mt: 1.5, mb: 1 }}>{text}</Typography>
      )}
      {editable && <CardFormButtonGroup onCancel={cancelChanges} onSave={savePost} />}
    </Box>
  );
})(() => ({
  '.PostContentItem': {
    '&__image': {
      height: 125,
      '&.oneColumn': {
        height: 250,
      },
      position: 'relative',
      width: '100%',
      borderRadius: '10px',
      overflow: 'hidden',
      '> img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        backgroundColor: 'black',
      },
      '&_delete_btn': {
        inset: 0,
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFF',
        background: 'rgba(0,0,0,0.6)',
        flexDirection: 'column',
        '.MuiButton-root': {
          color: '#FFF',
          flexDirection: 'column',
          textTransform: 'none',
          '.MuiSvgIcon-root': {
            fontSize: 40,
          },
        },
      },
    },
  },
}));

export default PostContentItem;
