import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryConfig } from '@utils/api';
import { BaseContentStatuses, BaseRequestFilters, ExplicitTypes, ListResponseWithPagination, PartialRequestQueryArgs } from '@src/types';
import { BaseContentsModel, UserContentCounters } from '@src/types/models';

export type UserContentFilterType = BaseRequestFilters & {
  type__in?: number[];
  // text?: string;
};

type UserContentRequestQueryArgs = PartialRequestQueryArgs & {
  order?: string;
  filter?: UserContentFilterType;
  include?: string;
};

export const userContentsApi = createApi({
  reducerPath: 'userContentsApi',
  baseQuery: baseQueryConfig(),
  tagTypes: ['UserContents'],
  endpoints: build => ({
    userContents: build.query<ListResponseWithPagination<BaseContentsModel>, UserContentRequestQueryArgs>({
      query: ({ page_size = 20, filter, ...params }) => ({
        url: 'usercontent/',
        params: {
          ...params,
          page_size,
          filter: filter && JSON.stringify(filter),
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: 'UserContents' as const,
                id,
              })),
              'UserContents',
            ]
          : ['UserContents'],
    }),
    userContentInfo: build.query<BaseContentsModel | null, string>({
      query: (userContentId: string) => ({
        url: `usercontent/${userContentId}`,
      }),
    }),
    userContentCounters: build.query<{ [contentId: string]: UserContentCounters }, { ids: string[] }>({
      query: ({ ids }) => ({
        url: 'counters/usercontent/',
        params: { ids: JSON.stringify(ids) },
      }),
    }),
    changeContentStatus: build.mutation<
      BaseContentsModel,
      {
        status: BaseContentStatuses;
        contentId: string;
      }
    >({
      query: ({ contentId, status }) => ({
        method: 'PATCH',
        url: `usercontent/${contentId}`,
        body: {
          status,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'UserContents', id: arg.contentId }],
    }),

    changeContentData: build.mutation<
      any,
      {
        contentId: string;
        status?: number;
        published?: string;
        updated?: string;
        created?: string;
        markers?: number[];
        explicit?: ExplicitTypes;
        moderation?: number;
        retailmention?: number;
        content?: {
          title?: string;
          text?: string;
          description?: string;
        };
      }
    >({
      query: ({ contentId, status, published, updated, created, markers, explicit, moderation, retailmention, content }) => ({
        method: 'PATCH',
        url: `usercontent/${contentId}`,
        body: {
          status,
          published,
          updated,
          created,
          markers,
          explicit,
          moderation,
          retailmention,
          content,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'UserContents', id: arg.contentId }],
    }),
  }),
});

export const { useUserContentsQuery, useUserContentCountersQuery, useChangeContentStatusMutation, useChangeContentDataMutation, useUserContentInfoQuery } =
  userContentsApi;
